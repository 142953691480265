import "./Home.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Home()
{
    const FILTER = {
        'brightness' : {
            value : 100,
            maxVal : 200,
            unit : "%"
        },
        'contrast' : {
            value : 100,
            maxVal : 200,
            unit : "%"
        },
        'grayscale' : {
            value : 0,
            maxVal : 100,
            unit : "%"
        },
        'saturation' : {
            value : 100,
            maxVal : 200,
            unit : "%"
        },
        'hueRotate' : {
            value : 0,
            maxVal : 360,
            unit : "deg"
        },
        'blur' : {
            value : 0,
            maxVal : 10,
            unit : "px"
        },
        'image' : ''
    };

    const [filters, setFilters] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState("brightness");
    const [details, setDetails] = useState('');

    const nav = useNavigate();

    useEffect(() => {
        setFilters(FILTER);
        if(window.sessionStorage.getItem("token") != null)
        {
            let url = "https://raganindustries.com/api_methods/authenticate.php";
            let values = {
                token : window.sessionStorage.getItem("token")
            };
            fetch(url, {
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json'
                },
                body : JSON.stringify(values)
            }).then((response) => response.json()).then((data) => {
                console.log(data);
                if(data == "Unauthorized")
                {
                    nav("/");
                }
            })
        }
        else
        {
            nav("/");
        }
    }, []);

    function handleChange(e)
    {
        let tempfilter = [];
        for(let i in filters)
        {
            tempfilter[i] = filters[i];
        }
        tempfilter[selectedFilter].value = e.target.value;
        setFilters(tempfilter);
    }

    function imageHandler(e)
    {
        if(e.target.files.length != 0)
        {
            const reader = new FileReader();
            reader.onload = () => {
                setFilters({
                    ...filters,
                    image : reader.result
                })
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function resetFilters()
    {
        setFilters(FILTER);
    }

    function saveImage()
    {
        const canvas = document.createElement("canvas");
        canvas.width = details.naturalWidth;
        canvas.height = details.naturalHeight;
        const context = canvas.getContext("2d");
        context.filter =    `
                            brightness(${filters['brightness'].value}${filters['brightness'].unit})
                            saturate(${filters['saturation'].value}${filters['saturation'].unit})
                            grayscale(${filters['grayscale'].value}${filters['grayscale'].unit})
                            contrast(${filters['contrast'].value}${filters['contrast'].unit})
                            hue-rotate(${filters['hueRotate'].value}${filters['hueRotate'].unit})
                            blur(${filters['blur'].value}${filters['blur'].unit})
                            `;
        context.translate(canvas.width / 2, canvas.height / 2);
        context.drawImage(details, -canvas.width/2, -canvas.height/2, canvas.width, canvas.height);
        const link = document.createElement('a');
        link.download = 'image_edit.jpg';
        link.href = canvas.toDataURL();
        link.click();
    }
    return(
        <div className = "container">
            <div className = "editor">
                <h1>Edit your Image</h1>
                { filters != null ?
                <img
                    id = "image"
                    src = { filters['image'] != "" ? filters['image'] : "/Resources/Background.png" }
                    alt = "error"
                    style = {{ filter : `
                                        brightness(${filters['brightness'].value}${filters['brightness'].unit})
                                        saturate(${filters['saturation'].value}${filters['saturation'].unit})
                                        grayscale(${filters['grayscale'].value}${filters['grayscale'].unit})
                                        contrast(${filters['contrast'].value}${filters['contrast'].unit})
                                        hue-rotate(${filters['hueRotate'].value}${filters['hueRotate'].unit})
                                        blur(${filters['blur'].value}${filters['blur'].unit})
                                        ` }}
                    onLoad = { (e) => { setDetails(e.currentTarget) } }
                />
                :
                null
                }
                <input type = "file" id = "choose" onChange = { imageHandler } />
                <br/><br/>
                <label htmlFor = "choose" >Choose Image</label>
                <div className = "options">
                    <h2>Filters</h2>
                    <div className = "filter">
                        <button onClick = { () => { setSelectedFilter("brightness") } } style = {{ background : selectedFilter == 'brightness' ? 'rgb(8, 244, 169)' : 'rgb(255, 255, 255)', color : selectedFilter == "brightness" ? 'white' : 'black' }} >Brightness</button>
                        <button onClick = { () => { setSelectedFilter("saturation") } } style = {{ background : selectedFilter == 'saturation' ? 'rgb(8, 244, 169)' : 'rgb(255, 255, 255)', color : selectedFilter == "saturation" ? 'white' : 'black' }} >Saturation</button>
                        <button onClick = { () => { setSelectedFilter("grayscale") } } style = {{ background : selectedFilter == 'grayscale' ? 'rgb(8, 244, 169)' : 'rgb(255, 255, 255)', color : selectedFilter == "grayscale" ? 'white' : 'black' }} >Gray Scale</button>
                        <button onClick = { () => { setSelectedFilter("contrast") } } style = {{ background : selectedFilter == 'contrast' ? 'rgb(8, 244, 169)' : 'rgb(255, 255, 255)', color : selectedFilter == "contrast" ? 'white' : 'black' }} >Contrast</button>
                        <button onClick = { () => { setSelectedFilter("hueRotate") } } style = {{ background : selectedFilter == 'hueRotate' ? 'rgb(8, 244, 169)' : 'rgb(255, 255, 255)', color : selectedFilter == "hueRotate" ? 'white' : 'black' }} >Hue Rotate</button>
                        <button onClick = { () => { setSelectedFilter("blur") } } style = {{ background : selectedFilter == 'blur' ? 'rgb(8, 244, 169)' : 'rgb(255, 255, 255)', color : selectedFilter == "blur" ? 'white' : 'black' }} >Blur</button>
                    </div>
                    <input 
                        type = "range"
                        value = { filters != null ? filters[selectedFilter].value : 0 }
                        max = { filters != null ? filters[selectedFilter].maxVal : 0 }
                        onChange = { handleChange }
                    />
                    <div className = "save">
                        <button onClick = { resetFilters } >Reset</button>
                        <br/>
                        <button onClick = { saveImage } >Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;