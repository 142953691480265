import "./SignIn.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SignIn()
{
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const nav = useNavigate();

    useEffect(() => {
        if(window.sessionStorage.getItem("token") != null)
        {
            window.sessionStorage.removeItem("token");
        }
    }, []);

    function handleUserNameChange(e)
    {
        setUserName(e.target.value);
    }

    function handlePasswordChange(e)
    {
        setPassword(e.target.value);
    }

    function handlePasswordChange2(e)
    {
        setPassword2(e.target.value);
    }

    function handleClick()
    {
        let url = "https://raganindustries.com/api_methods/signin.php";
        let values = {
            username : username,
            password : password
        };
        fetch(url, {
            method : 'POST',
            headers : {
                'Accept' : 'application/json',
                'Content-type' : 'application/json'
            },
            body : JSON.stringify(values)
        }).then((response) => response.json()).then((data) => {
            if(data == "Account created")
            {
                nav("/");
            }
            else
            {
                alert(data);
            }
        });
    }

    return(
        <div className = "container">
            <div className = "signin">
                <h1>SignIn</h1>
                <input type = "text" value = { username } placeholder = "Enter username" onChange = { handleUserNameChange } />
                <div className = "border"></div>
                <br/><br/>
                <input type = "password" value = { password } placeholder = "Enter password" onChange = { handlePasswordChange } />
                <div className = "border"></div>
                <br/><br/>
                <input type = "password" value = { password2 } placeholder = "Enter password" onChange = { handlePasswordChange2 } />
                <div className = "border"></div>
                <br/><br/>
                <input type = "submit" value = "Sign In" onClick = { handleClick } />
            </div>
        </div>
    );
}

export default SignIn;