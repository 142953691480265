import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Authenticate/Login";
import SignIn from "./Authenticate/SignIn";
import Home from "./Components/Home";

function App()
{
  return(
    <div>
      <BrowserRouter>
        <Routes>
          <Route path = "/" element = { <Login /> } />
          <Route path = "/signin" element = { <SignIn /> } />
          <Route path = "/home" element = { <Home /> } />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;